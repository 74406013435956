import Vue from 'vue'
// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { createModule } from 'vuexok'
import { ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import { EmailsListState, IEmailTemplatesListRequest } from './types'
import { cloneEmailTemplate, deleteEmailTemplate, fetchEmailTemplatesList } from '@/api/emailTemplates'
import { DEFAULT_PER_PAGE, PER_PAGE_OPTIONS } from '@/constants/base'
import setPerPage from '@/helpers/setPerPage'

// eslint-disable-next-line import/prefer-default-export
export const appEmailsList = createModule(
  'appEmailsList',
  {
    namespaced: true,
    state: {
      tableColumns: [
        { key: 'name', label: 'name', sortable: true },
        { key: 'subject', label: 'subject', sortable: true },
        { key: 'created_by', label: 'created by', sortable: true },
        { key: 'actions' },
      ],
      perPage: Number(localStorage.getItem('emailsPerPage')) || DEFAULT_PER_PAGE,
      totalItems: 0,
      currentPage: 1,
      perPageOptions: PER_PAGE_OPTIONS,
      searchQuery: '' as string | (string | null)[],
      sortBy: 'created_at',
      isSortDirDesc: true,
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
      tableItems: ref([]),
      isLoading: false,
    } as EmailsListState,
    getters: {
      getTableColumns: state => state.tableColumns,
      getPerPage: state => state.perPage,
      getTotalItems: state => state.totalItems,
      getCurrentPage: state => state.currentPage,
      getPerPageOptions: state => state.perPageOptions,
      getSearchQuery: state => state.searchQuery,
      getSortBy: state => state.sortBy,
      getIsSortDirDesc: state => state.isSortDirDesc,
      getDataMeta: state => state.dataMeta,
      getIsLoading: state => state.isLoading,
      getTableItems: state => state.tableItems,
      selectedFolder() {
        return store.getters['app-folders/getSelectedFolder']
      },
      forceReload() {
        return store.getters['app-folders/getForceReload']
      },
      selectedFolderId() {
        const folder = store.getters['app-folders/getSelectedFolder']
        return folder ? folder.id : null
      },
    },
    mutations: {
      SET_PER_PAGE(state, val: number) {
        localStorage.setItem('emailsPerPage', val.toString())
        state.perPage = val
      },
      SET_TOTAL_ITEMS(state, val: number) {
        state.totalItems = val
      },
      SET_CURRENT_PAGE(state, val: number) {
        state.currentPage = val
      },
      SET_SEARCH_QUERY(state, val: string) {
        state.searchQuery = val
      },
      SET_SORT_BY(state, val: string) {
        state.sortBy = val
      },
      SET_IS_SORT_DESC(state, val: boolean) {
        state.isSortDirDesc = val
      },
      SET_DATA_META(state, val) {
        state.dataMeta = val
      },
      SET_TABLE_ITEMS(state, val: any) {
        state.tableItems = val
      },
      SET_FILTER_PARAMS(state) {
        const query = { ...router.currentRoute.query }
        const defaultPerPage = localStorage.getItem('emailsPerPage')
        state.currentPage = Number(query.currentPage) || 1
        state.perPage = setPerPage(query.perPage, defaultPerPage, DEFAULT_PER_PAGE)
        state.searchQuery = query.searchQuery || ''
      },
      SET_IS_LOADING(state, val: boolean) {
        state.isLoading = val
      },
    },
    actions: {
      async fetchEmailTemplatesList({ state }) {
        try {
          appEmailsList.mutations.SET_IS_LOADING(true)
          const queryParams: IEmailTemplatesListRequest = {
            perPage: state.perPage,
            page: state.currentPage || 1,
            sortBy: state.sortBy,
            sortDesc: state.isSortDirDesc,
            form_folder: true,
          }
          if (state.searchQuery) queryParams.name = state.searchQuery
          if (router.currentRoute.query.folder_id) queryParams.folder_id = router.currentRoute.query.folder_id
          const response:any = await fetchEmailTemplatesList(queryParams)

          const {
            total, from, to,
          } = response.data.meta

          const dataMeta = {
            from,
            to,
            of: total,
          }

          appEmailsList.mutations.SET_TOTAL_ITEMS(total)
          appEmailsList.mutations.SET_DATA_META(dataMeta)
          appEmailsList.mutations.SET_TABLE_ITEMS(response.data.data)
          return response.data.data
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching email' list",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return []
        } finally {
          appEmailsList.mutations.SET_IS_LOADING(false)
        }
      },
      async deleteEmailTemplate(ctx, id) {
        try {
          appEmailsList.mutations.SET_IS_LOADING(true)
          await deleteEmailTemplate(id)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success deleting email',
              icon: 'Trash2Icon',
              variant: 'info',
            },
          })
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error deleting email',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        } finally {
          appEmailsList.mutations.SET_IS_LOADING(false)
        }
      },
      async cloneEmailTemplate(ctx, id: string) {
        try {
          appEmailsList.mutations.SET_IS_LOADING(true)
          await cloneEmailTemplate(id)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success cloning template',
              icon: 'Trash2Icon',
              variant: 'info',
            },
          })
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error cloning template',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        } finally {
          appEmailsList.mutations.SET_IS_LOADING(false)
        }
      },
    },
  },
)

appEmailsList.register(store)
