























































































































































































































import Vue from 'vue'

import {
  BCard, BRow, BCol, BFormInput, BPagination,
  BDropdown, BDropdownItem, BButton, BLink, BOverlay,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import store from '@/store'
import pushStateFiltersParams from '@/helpers/pushStateFiltersParams'
import { appEmailsList } from '@/store/modules/app-emails-list'
import { permissionSubjects } from '@/libs/acl/constants'
import MoveToFolderModal from '@/views/components/folders-list/modals/MoveToFolderModal.vue'

let debounceTimeout: ReturnType<typeof setTimeout> = setTimeout(() => '', 1000)

export default Vue.extend({
  name: 'EmailsList',
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BPagination,
    BDropdown,
    BDropdownItem,
    BButton,
    BLink,

    vSelect,

    MoveToFolderModal,
  },
  data() {
    return {
      currentRouteName: this.$router.currentRoute.name,
      permissionSubjects,
      selectedEmailTemplate: {},
    }
  },
  computed: {
    selectedFolder() {
      return appEmailsList.getters.selectedFolder
    },
    selectedFolderId() {
      return appEmailsList.getters.selectedFolderId
    },
    forceReload() {
      return appEmailsList.getters.forceReload
    },
    tableColumns() {
      return appEmailsList.getters.getTableColumns
    },
    perPage() {
      return appEmailsList.getters.getPerPage
    },
    totalItems() {
      return appEmailsList.getters.getTotalItems
    },
    isLoading() {
      return appEmailsList.getters.getIsLoading
    },
    tableItems() {
      return appEmailsList.getters.getTableItems
    },
    currentPage: {
      get() {
        return appEmailsList.getters.getCurrentPage
      },
      set(val: number) {
        appEmailsList.mutations.SET_CURRENT_PAGE(val)
      },
    },
    perPageOptions() {
      return appEmailsList.getters.getPerPageOptions
    },
    searchQuery: {
      get() {
        return appEmailsList.getters.getSearchQuery
      },
      set(val: string) {
        appEmailsList.mutations.SET_SEARCH_QUERY(val)
      },
    },
    sortBy() {
      return appEmailsList.getters.getSortBy
    },
    isSortDirDesc() {
      return appEmailsList.getters.getIsSortDirDesc
    },
    dataMeta() {
      return appEmailsList.getters.getDataMeta
    },
  },
  watch: {
    $route() {
      if (!this.$route.query.currentPage) {
        this.setFilterParams()
      }
      this.updateTable()
    },
    currentPage(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'currentPage', val)
      }
    },
    perPage(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'perPage', val)
      }
    },
    searchQuery(val, prevVal) {
      if (val !== prevVal) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          pushStateFiltersParams(this, this.currentRouteName, 'searchQuery', val)
        }, 250)
      }
    },
    forceReload() {
      this.updateTable()
    },
    selectedFolder() {
      this.updateTable()
    },
  },
  async mounted() {
    this.setFilterParams()
    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }

    await appEmailsList.actions.fetchEmailTemplatesList()
  },
  methods: {
    updatePerPage(val: number) {
      appEmailsList.mutations.SET_PER_PAGE(val)
    },
    async fetchEmailTemplatesList() {
      return appEmailsList.actions.fetchEmailTemplatesList()
    },
    updateTable() {
      appEmailsList.actions.fetchEmailTemplatesList()
    },
    async deleteItem(id: string) {
      await appEmailsList.actions.deleteEmailTemplate(id)
      this.updateTable()
    },
    async cloneTemplate(id: string) {
      await appEmailsList.actions.cloneEmailTemplate(id)
      this.updateTable()
    },
    async moveTo(emailTemplate: object) {
      this.selectedEmailTemplate = emailTemplate
      this.$nextTick(() => {
        this.$bvModal.show('move-to-folder-modal')
      })
    },
    dragStart(event: any, id: string) {
      if (this.$can('update', permissionSubjects.EmailTemplate)) {
        const data = {
          id,
          type: 'email-template',
        }
        event.dataTransfer.setData('text/plain', JSON.stringify(data))
      } else {
        event.preventDefault()
      }
    },
    setFilterParams() {
      appEmailsList.mutations.SET_FILTER_PARAMS()
    },
    backToPrevPage() {
      store.commit('app-folders/SET_SELECTED_FOLDER', null)

      this.$router.push({ name: 'admin-email-templates-folders' })
    },
  },
})
